﻿$color-text-readonly: rgba(0, 0, 0, 0.3);

.error-text {
  color: red !important;
}

.smart-select-list .text-block {
  margin-bottom: 19px;
  margin-left: 5px;
}

.smart-select-list .fa-magnifying-glass {
  display: inline-block;
  margin-top: 9px;
  font-size: 20px;
  margin-left: 5px;
}

.smart-select-list input {
  width: 85% !important;
  margin-left: 5px !important;
}

.smart-select-list {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.smart-select-list > * {
  width: 50%;
}

.smart-select-list a {
  cursor: pointer;
  float: right;
  margin-right: 5px;
}

.smart-select-list ul {
  margin: 0;
  padding: 0;
  height: 170px;
  /*max-height: 170px;*/
  overflow-y: scroll;
  cursor: pointer;

  .filtered-group-members {
    overflow-y: visible;
    margin-left: 25px;
    height: auto;
  }
}

.smart-select-list li i {
  padding-right: 3px;
}

.search-list {
  border: 1px solid #ddd;
  background-color: #efefef;
  margin: 5px;
  padding: 5px;
}

.selected-list {
  border: 1px solid #cec;
  background-color: #ddffdd;
  margin: 5px;
  padding: 5px;
}

.smart-select-list--read-only {
  ul {
    cursor: default;
  }

  .search-list,
  .selected-list {
    color: $color-text-readonly;
  }

  .search-list {
    margin-top: 62px;
  }
}

.small-text {
  margin: 0;
  font-size: 13px;
  display: block;
  font-weight: 400;
  width: 100%;
  margin-top: -8px;
  line-height: 1.5em;
}

.loading-message {
  min-height: 400px;
  padding: 20px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  color: #888;
}

.smart-select-list__more-result-text {
  font-style: italic;
}
